.swiper_Top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px 10px 18px;
}
.swiper_Top .swiper_Title {
  display: flex;
  align-items: center;
}
.swiper_Top .swiper_Title .swiper_Title_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.swiper_Top .swiper_Title .swiper_Title_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.swiper_Top .swiper_Title p {
  margin-left: 10px;
  color: #fff;
}
.swiper-slide > div {
  background-color: #000;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 100% !important;
  position: relative;
}
.swiper_top_menu {
  color: #fff;
  padding: 5px 0;
  display: flex;
  overflow: hidden;
}
.swiper_top_menu ul {
  display: flex;
  overflow: hidden;
}
.swiper_top_menu ul li {
  width: fit-content;
  min-width: fit-content;
  padding: 0 12px;
  text-align: left;
  min-width: 100%;
}
.swiper_top_menu ul li.active {
  text-decoration: underline;
}
.swiper_top_back_btn button img {
  margin-top: 8px;
}
.swiper_top_back_btn button {
  height: fit-content;
  line-height: initial;
  padding: 0;
}
.swiper_top_back_btn {
  width: 10px;
  min-width: 10px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
}
.swiper_top_menu .dishItems {
  width: 100%;
}
.swipper_bottom {
  color: #fff;
  padding: 10px 10px 15px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.swipper_bottom_first {
  display: flex;
  justify-content: space-between;
}
.addcart_btn button {
  height: 41px;
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 0 3px 0 16px;
  background: #f8edd6;
}
.addcart_btn button p {
  margin-right: 10px;
  color: #a26057;
}
.addcart_btn button span {
  color: #fff;
  background-color: #e9804e;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: inherit;
  border-radius: 50%;
  font-size: 40px;
  justify-content: center;
  line-height: 25px;
}
.items_price {
  margin-right: 10px;
}
.swipper_bottom_first .items_value .plus_taxes p {
  color: #949494;
  margin-top: 5px;
  margin-left: 9px;
}
.items_value .items_price h5 {
  color: #e6e6e6;
  font-size: 18px;
}
.items_value .items_price h6 {
  color: #c5c5c5;
  font-size: 14px;
}
.swipper_bottom > h6 {
  color: #a85c39;
  font-size: 14px;
  margin-top: 8px;
}
/* .swipper_main_img {
  height: 257px;
  min-height: 257px;
  max-height: 257px;
} */
.swipper_main_img img {
  width: 100%;
  height: 100%;
}

.plus_taxes {
  display: flex;
  margin: 5px 0px 1px 0px;
}
@supports (-webkit-touch-callout: none) {
.stories_wrapper{
    max-height: 85vh !important;
}
.single_stories_wrapper>div>div:nth-child(2){
    height: 65vh !important;
}
.single_stories_wrapper>div{
    justify-content: center !important;
}
.single_stories_wrapper>div>div:nth-child(1) {
    top: 0 !important;
}
#dishclick.AppContainer_left__8clJu{
    min-height: 85vh !important;
}
.swiper-container{
    height: initial !important;
    background: #111 !important;
}
.sess{
    height: 100%;
    background: #111 !important;
}
 .swipper_bottom{
    bottom: 70px;
}
}
