.orderSummary_wrapper {
  padding-bottom: 8rem;
}
.orderSummary_top {
  text-align: center;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderSummary_icon {
  margin-right: 1rem;
}
.order-info {
  margin-top: 30px;
  padding: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.order-info_span {
  font-weight: lighter;
}

.order-info_right {
  width: 40%;
}

.delivery-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.6rem;
  padding: 2px;
}

.status_badge {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  /* background-color: #7d9a6f; */
  color: green;
}

.delivery-address {
  margin-top: 2rem;
}

.delivery-address_top {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}
.delivery-address_span {
  font-weight: bold;
}

.delivery-address_content {
  width: 80%;
  padding-left: 15px;
  margin-top: 0.5rem;
}

.order-details {
  width: 90%;
  margin: 15px;
  font-weight: bold;
  margin-top: 1rem;
}

.ordered-dishes_container {
  margin-top: 1rem;
  padding: 0px 15px 0px 15px;
}
