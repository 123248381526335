.address-wrapper {
  width: 100% !important;
  padding-bottom: 0 !important;
}

.address-card_wrapper {
  margin: 100px auto 0px auto;
  width: 90%;
}

.add-address_btn {
  border: 1px solid rgb(203, 197, 197);
  width: 95%;
  padding: 20px;
  margin: auto;
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.select_btn {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.address-form_actionbtn:focus {
  background-color: orangered;
}

.selected {
  color: orangered;
}

.address_card {
  margin-top: 4rem;
}

.tag_container h6 {
  font-weight: bold;
  text-transform: uppercase;
}

.tag_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}
