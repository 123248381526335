.gps_wrapper {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

.gps_hr {
  margin-top: -6px;
}

.gps_content {
  width: 90%;
}
