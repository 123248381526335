.stories_wrapper{
    height: 100vh;
    width: 100%;
}
.single_stories_wrapper>div{
    height: 100% !important;
    width: 100% !important;
    z-index: 999;
}
.single_stories_wrapper>div>div:nth-child(2){
    height: 100% !important;
    width: 100% !important;
    z-index: 9999;
}
.single_stories_main{
    width: 100%;
}