.address-form_wrapper {
  width: 100% !important;
  padding-bottom: 0 !important;
}

.address-form {
  margin-top: 70px;
  width: 100%;
}
.form-input_container {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

.address-form input,
h5 {
  all: unset;
  width: 50%;
  font-size: 20px;
}

.address-form-input_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.address-form-input_container label {
  font-size: 14px;
  color: grey;
}

#address-form-input {
  all: unset;
  border-bottom: 2px solid #d2cccc;
  color: grey;
  line-height: 2.5rem;
}

.tagas_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.tagas_container .tag {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #d2cccc;
  color: grey;
}

.tag_active {
  background-color: transparent;
  border: none;
  border-bottom: 3px solid #f35314;
  color: black;
  outline: none;
  font-weight: bold;
}
.tag_active:focus {
  background-color: transparent;
}

.actionBtn_container {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.address-form_actionbtn {
  margin: 0px auto 0px auto;
  width: 100%;
  padding: 15px;
  font-weight: bold;
  background-color: #f35314;
  color: white;
  border: none;
}

