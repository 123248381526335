.recent-searches_wrapper {
  padding: 20px;
}

.recent_hr {
  margin-top: 1rem;
}

.recent-address_container {
  margin-top: 2rem;
  line-height: 2rem;
}

.recent-searches_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recent-address_container {
  width: 85%;
}

.recent-address_container h6 {
  font-weight: bold;
  text-transform: capitalize;
  line-height: 2rem;
}
