.swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  margin-left: 0px;
  margin-top: 0px;
}
.swiper-slide>div>div:nth-child(2) >div{
 z-index: 9999;
 width: 100%;
} 
.swiper-slide>div>div:nth-child(2){
  width: 100% !important;
  height: 95vh !important;
 } 
.swiper-slide>div>div:nth-child(1){
  top: 0;
   } 

/* .swiper-slide img {
  display: block;
  width: 100%;
}  */
