.footer-wrapper {
    width: 100%;
    margin-top: auto;
    background-color: #F8EDD5;
}

.footer-wrapper .footer-links {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    list-style-type: none;
    padding-left: 0
}

.footer-wrapper .footer-links figure {
    text-align: center
}

.footer-wrapper .footer-links figure img {
    width: 35px;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center bottom;
    object-position: center bottom
}

@media only screen and (max-width: 576px) {
    .footer-wrapper .footer-links figure img {
        width: 20px;
        height: 20px	
    }
    .footer-wrapper {
    	position: fixed;
        /* position: sticky; */
    	bottom: 0;
	}
}

.footer-wrapper .footer-links figure figcaption {
    color: #670000;
    font-size: 10px;
    font-weight: 800
}

@media only screen and (max-width: 576px) {
    .footer-wrapper .footer-links figure figcaption {
        font-size: 8px
    }
}