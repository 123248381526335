/* .search_wrapper {
    border:2px solid black;
} */



.location-search_back-btn {
    /* border: 2px solid black; */
    width: 5%;
}

.location-search_input {
    /* border: 2px solid black; */
    width: 80%;
}

.location-search_img {
    /* border: 2px solid black; */
    width: 10%;
}